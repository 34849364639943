import { MatSnackBar } from '@angular/material/snack-bar';
import { MESSAGE } from '@app/pages/projects/Task-management/constants';

export function convertHoursToMinutes(hours: number): {
  hours: number;
  minutes: number;
} {
  return {
    hours: Math.floor(hours),
    minutes: Math.round((hours - Math.floor(hours)) * 60),
  };
}

export function formatTime(time: string) {
  if (this.taskDetails['totalTimeLogs'] !== null) {
    const hours =
      (new Date('2023/01/01 ' + this.taskDetails['totalTimeLogs']).getHours() <
      10
        ? '0'
        : '') +
      new Date('2023/01/01 ' + this.taskDetails['totalTimeLogs']).getHours();
    const minutes =
      (new Date(
        '2023/01/01 ' + this.taskDetails['totalTimeLogs'],
      ).getMinutes() < 10
        ? '0'
        : '') +
      new Date('2023/01/01 ' + this.taskDetails['totalTimeLogs']).getMinutes();
    time = hours + ':' + minutes;
  } else {
    time = '00:00';
  }

  return time;
}

export function parseValueToNumber(value: any): number {
  if (value === 0 || value === undefined || value === '') {
    return 0;
  } else {
    value = typeof value === 'string' ? value.replace('$', '') : value;
    if (typeof value === 'string' && value.startsWith('(')) {
      return -parseFloat(value?.toString().replace(/[,\(\)]/g, ''));
    } else {
      return parseFloat(value?.toString().replace(/,/g, ''));
    }
  }
}

export function formatNumber(number: number) {
  if (number < 0) {
    return `($${Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`;
  } else {
    if (number !== null && number != undefined) {
      return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }
}

export function replaceCommaSeprateAndBracket(num: string) {
  if (num == '' || num === undefined) {
    return;
  }
  return num.replace(/[,\(\)]/g, '');
}

export function formatPercentage(number: number) {
  Math.abs(number).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (number < 0) {
    const percentage = Math.abs(number).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return '(' + percentage + ')';
  } else {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

export function formatNegativeNumber(number: number) {
  if (number < 0) {
    return `${Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  } else {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

export function checkNullValuesObject(obj) {
  for (let key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== 0 &&
      obj[key] !== MESSAGE.EMPTY_GUID
    ) {
      return false;
    }
  }
  return true;
}

export function getDuration(startTime: Date | string, endTime: Date | string) {
  // Convert the input times to Date objects if they are not already
  const start: Date = new Date(startTime);
  const end: Date = new Date(endTime);

  // Calculate the difference in milliseconds
  const diff: number = end.getTime() - start.getTime();

  // Convert the difference to a more readable format
  const milliseconds: number = diff % 1000;
  const seconds: number = Math.floor((diff / 1000) % 60);
  const minutes: number = Math.floor((diff / (1000 * 60)) % 60);
  const hours: number = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const days: number = Math.floor(diff / (1000 * 60 * 60 * 24));

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    milliseconds: milliseconds,
  };
}

export function exportPdf(id, filename) {
  setTimeout(() => {
    var domToPdf = require('dom-to-pdf');
    var element = document.getElementById(id);
    var options = {
      filename: `${filename}.pdf`,
    };
    domToPdf(element, options, function () {});
  }, 100);
}

export function formatNegativeNumberWithMinusSign(number: number) {
  return number < 0
    ? `-${Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    : number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}

export function allowAlphanumericAndSpecialChars(event: KeyboardEvent) {
  const charCode = event.which ? event.which : event.keyCode;
  const charStr = String.fromCharCode(charCode);
  const regex = /^[a-zA-Z0-9-_ ]$/;

  if (!regex.test(charStr)) {
    event.preventDefault();
  }
}

export function allowNumbersAndSpecialChars(event: KeyboardEvent) {
  const charCode = event.which ? event.which : event.keyCode;
  const charStr = String.fromCharCode(charCode);
  const regex = /^[a-zA-Z0-9-_]$/;
  if (!regex.test(charStr)) {
    event.preventDefault();
  }
}

export function allowEmailCharacters(event: KeyboardEvent) {
  const charCode = event.which ? event.which : event.keyCode;
  const charStr = String.fromCharCode(charCode);
  const regex = /^[a-zA-Z0-9@._\-+]+$/;

  if (!regex.test(charStr)) {
    event.preventDefault();
  }
}

export function formatWithPositiveNumber(number: number) {
  if (number < 0) {
    return `($${Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`;
  } else {
    if (number !== null && number != undefined) {
      return `$${number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
  }
}

// Only allows numbers and special characters. Returns true if there are alphabets
export function isContainsAlphabets(value: string) {
  const regex = /^[^a-zA-Z]+$/;
  return !regex.test(value);
}

export function showSnackBar(
  snackBar: MatSnackBar,
  message: string,
  verticalPosition: 'top' | 'bottom' = 'top',
): void {
  snackBar.open(message, '', {
    duration: 1000,
    verticalPosition: verticalPosition,
    panelClass: 'custom-snackbar-class',
  });
}

export function isOnlyNumbersOrSpecialChars(value: string): boolean {
  const regex = /^[^a-zA-Z]*$/; // Matches strings with no alphabet characters.
  return regex.test(value);
}

export function isFileExtensionAllowed(file: File): boolean {
  const allowedExtensions = [
    '.txt',
    '.csv',
    '.pdf',
    '.docx',
    '.xlsx',
    '.png',
    '.jpeg',
    '.jpg',
    '.gif',
    '.zip',
  ];

  const fileName = file.name;
  const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();

  return allowedExtensions.includes(fileExtension);
}

// Allow only numbers (0-9)
export function allowOnlyNumbers(event: KeyboardEvent) {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
}
export function allowOnlyNumbersAndDecimal(event: KeyboardEvent) {
  const charCode = event.which ? event.which : event.keyCode;

  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  const inputValue = (event.target as HTMLInputElement).value;

  if (charCode === 46 && inputValue.includes('.')) {
    event.preventDefault();
  }
}

export function isFileExtensionAllowedForSchedule(file: File): boolean {
  const allowedExtensions = ['.xer', '.mpp'];

  const fileName = file.name;
  const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();

  return allowedExtensions.includes(fileExtension);
}

export function formatNumberWithComma(value: string | number): string {
  if (value === null || value === undefined || value === '' || value === 0) {
    return '$0.00';
  }
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(numericValue)) {
    return '$0.00';
  }

  if (numericValue < 0) {
    return `($${Math.abs(numericValue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`;
  }

  return `$${numericValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}

export function roundToTwoDecimals(value: any): number {
  const numberValue = parseFloat(value);
  if (!isNaN(numberValue)) {
    return Math.round(numberValue * 100) / 100; 
  }
  return 0;
}

export function allowOnlyNumbersAndDecimalWithNoNegative(event: KeyboardEvent) {
  const charCode = event.which ? event.which : event.keyCode;

  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }
  const inputValue = (event.target as HTMLInputElement).value;
  
  if (charCode === 46 && inputValue.includes('.')) {
    event.preventDefault();
  }

  if (charCode === 45) {
    event.preventDefault();
  }
}

export function validateInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  let inputValue = inputElement.value;

  if (inputValue.startsWith('-')) {
    inputValue = inputValue.substring(1);
  }
  inputValue = inputValue.replace(/[^0-9.]/g, '');
  const parts = inputValue.split('.');

  if (parts.length > 2) {
    inputValue = parts[0] + '.' + parts[1];
  }
  inputElement.value = inputValue;
}

function formatDate(value) {
  if (!value) return '';
    const date = new Date(value);
    if (isNaN(date.getTime())) return value;
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
  });  
}

export function getFinalList(data, labels = {}, dateColumns = [], amountColumns = []) {
  return data.map((item) => {
    let formattedItem = {};
    Object.keys(labels).forEach((key) => {
      let value = item[key];

      if (value === null || value === undefined) {
        value = "";
      }

      if (dateColumns.includes(key) && value) {
        value = formatDate(value);
      }

      if (amountColumns.includes(key)) {
        value = formatNumberWithComma(value);
      }
      
      formattedItem[labels[key]] = value;
    });
    return formattedItem;
  });
}